.root {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;
}

.link {
    display: inline-block;
    margin-bottom: 0.3rem;
    font-size: 1.8rem;
    font-weight: bold;
}

.item {
    color: #080;
    > span {
        color: #000;
    }
}
