.root {
    margin-top: 0.3rem;
}

.toggle {
    display: flex;
    align-items: center;
    padding: 0;
    color: #44f;
    &:hover > .text {
        text-decoration: underline;
    }
}

.text {
}

.button {
    padding: 0;
    margin: 0;
    width: 0.9rem;
    margin-left: 0.5rem;
    transform: rotate(90deg);
    &.expanded {
        transform: rotate(180deg);
    }
}

.headerList {
    padding: 0 0 0 2rem;
    margin: 0.2rem 0 1.5rem 0;
}

.headerItem {
    list-style: none;
    margin-top: 0.1rem;
}

.headerName {
    font-weight: bold;
}
